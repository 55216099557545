import React from 'react';
import PropTypes from 'prop-types';

const DateStrToMonthYear = (dateStr) => {
    if (dateStr === "TODAY")
        return "En cours";
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = date.toLocaleString('fr-FR', { month: 'long' });
    return `${month} ${year}`;
}

const StyleKeepClrf={whiteSpace:'pre-line'};

const Timeline = ( {CompanyName, FunctionName, Summary, StartDate, EndDate}) => {
    return (
        <div className="timeline-item clearfix">
            <h5 className="item-period ">{DateStrToMonthYear(StartDate)} - {DateStrToMonthYear(EndDate)} </h5>
            <span className="item-company">{CompanyName}</span>
            <h4 className="item-title">{FunctionName}</h4>
            <p style={StyleKeepClrf}>{Summary}</p>
        </div>
    );
};

Timeline.propTypes = {
    CompanyName : PropTypes.string,
    FunctionName : PropTypes.string,
    Summary: PropTypes.string,
    StartDate: PropTypes.string,
    EndDate: PropTypes.string  
};
export default Timeline;