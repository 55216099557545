//code pour composant ereact qui affiche les projets
import React from 'react';

const ProjetBloc = () => {
    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12">
            <div className="block-title">
                <h2>Liste de projet<span style={{color:'red'}}>Non implémenté</span></h2>
            </div>
            </div>
        </div>
    );
}

export default ProjetBloc;