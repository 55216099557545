import React from 'react';
import DataCV from '../../data/resume.json';


const SocialNetWork = () => {
    return (
        <div className="footer-social">
        <ul className="footer-social-links">
            {DataCV.basics.profiles.map((item, index) => (
                <li key={index}>                    
                    <i className={'fab fa-'+item.network.toLowerCase()} /><a href={item.url} target="_blank" rel="noopener noreferrer"> {item.network}</a>
                </li>
            ))}
        </ul>
      </div>
    );
};

export default SocialNetWork;