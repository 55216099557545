import React from 'react';
import {HashRouter, Route, Routes} from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Projets from './pages/Projets';
import Resume from './pages/Resume';
import NotFound from './pages/NotFound';
import Header from './components/Header';
import Footer from './components/Footer';

const App = () => {
  return (
    <>
          <div className="lm-animated-bg"></div>

<div className="preloader">
  <div className="preloader-animation">
    <div className="preloader-spinner">
    </div>
  </div>
</div>
<div className="lmpixels-scroll-to-top"><i className="lnr lnr-chevron-up"></i></div>

<div className="page-scroll">
  <div id="page_container" className="page-container bg-move-effect" data-animation="transition-flip-in-right">
  <HashRouter>
    <Header/>
    <div id="main" className="site-main">
          <div id="main-content" className="single-page-content">
            <div id="primary" className="content-area">      
              <Routes>
                <Route  exact path='/' element={<Home/>} />
                <Route  exact path='/contact' element={<Contact/>} />
                <Route  exact path='/projets' element={<Projets/>} />
                <Route  exact path='/resume' element={<Resume/>} />
                <Route  path="./*" element={<NotFound/>} />
              </Routes>
            </div>
          </div>
      </div>
      <Footer/>
      </HashRouter>
      </div>  
    </div>
    </>
  );
};

export default App;
