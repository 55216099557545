import React from 'react';
import PropTypes from 'prop-types';


const convertTextToImg = (text) => {
    return text.split('').reverse().map((letter, index) => {
        return <span key={index}>{letter}</span>;
    });
}

const InfoItem = ({ iconClass, caption, description }) => {
    return (
        <div className="info-block-w-icon">
            <div className="ci-icon">
                <i className={iconClass}></i>
            </div>
            <div className="ci-text">
                <h4 style={{unicodeBidi:'bidi-override', direction:'rtl', userSelect:'none'}}>{convertTextToImg(caption)}</h4>
                <p>{description}</p>
            </div>
        </div>
    );
};

InfoItem.propTypes = {
    iconClass: PropTypes.string.isRequired,
    caption: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default InfoItem;
