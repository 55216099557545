import React from 'react';
import Skill from './Skill';
import DataCV from '../../data/resume.json';

const SkillBloc = () => {
    return (
        <>
            <div className="row">
                <div className=" col-xs-12 col-sm-12 ">
                    <div className="p-40"></div>
                </div>
            </div>
            <div className="row">
                <Skill SkillTitle={DataCV.skilllist.find(skill =>skill.name === "Langage").name} SkillList={DataCV.skilllist.find(skill =>skill.name === "Langage").listvalue}/>
                <Skill SkillTitle={DataCV.skilllist.find(skill =>skill.name === "Base de données")?.name} SkillList={DataCV.skilllist.find(skill =>skill.name === "Base de données")?.listvalue}/>
                <Skill SkillTitle={DataCV.skilllist.find(skill =>skill.name === "Cloud/Infra")?.name} SkillList={DataCV.skilllist.find(skill =>skill.name === "Cloud/Infra")?.listvalue}/>
            </div>
                
    </>
    );
};

export default SkillBloc;