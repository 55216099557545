import React from 'react';
import SocialNetWork from './Footer/SocialNetWork';

const Footer = () => {
    return (
        <footer className="site-footer clearfix">
            <SocialNetWork />
          <div className="footer-copyrights">
            <p>© 2020 All rights reserved. LMPixels.</p>
          </div>
        </footer>

    );
};

export default Footer;