import React from 'react';
import PropTypes from 'prop-types';

const Skill = ( {SkillTitle, SkillList}) => {
    const sortedList = [...SkillList].sort((a, b) => b.value - a.value);
    const styleimg = {
        position: 'relative',
        float: 'left',
        lineHeight: '1.1em',
        marginRight: '5px',
    }
    const returnLogo = (item) => {
        if (item.url) {
            return (
                <div style={styleimg}><img src={item.url} alt={`Logo ${item.name}`} /></div>
            );
        }
        return null;
    }
    return (
        <div key={SkillTitle} className=" col-xs-12 col-sm-4 ">
            <div className="block-title">
                <h2>{SkillTitle}</h2>
            </div>
            {sortedList.map((item) => (
                <div key={item.name} className="skills-info skills-first-style">
                    <div className="clearfix"> 
                        {returnLogo(item)}                    
                        <div><h4>{item.name}</h4></div>
                        <div className="skill-value">{item.value}%</div>
                    </div>
                    <div data-value={item.value} className="skill-container">
                        <div className="skill-percentage" style={{ width: `${item.value}%` }}></div>
                    </div>
                </div>
            ))}
        </div>

    );
};

Skill.propTypes = {
    SkillTitle : PropTypes.string,
    SkillList : PropTypes.arrayOf(
        PropTypes.shape({
           name: PropTypes.string,
           value: PropTypes.number 
        })
    )
};
export default Skill;