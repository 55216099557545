import React from 'react';
import DataCV from '../data/resume.json';
import TimeLineBloc from '../components/Resume/TimeLineBloc';
import CertificateBloc from '../components/Resume/CertificateBloc';
import SkillBloc from '../components/Resume/SkillBloc';
import Interest from '../components//Resume/Interest';
import CV_JD from '../assets/cv/CV-Jean DELAGOUTTE.pdf';




const Resume = () => {
/**
 * Permet de calculer automatiquement le nombre d'année depuis la première expérience de travail        
 *
 * @return  {[number]}  nombre d'année
 */
    const BeginWork = () => {
        DataCV.work.sort(function(a,b) {
            var date1 = (new Date(a.startDate));
            var date2 = (new Date(b.startDate));
            return date1 - date2;
          });
        let MinDate = new Date(DataCV.work[0].startDate);
        let diff = Math.floor(Date.now() - MinDate);
        let day = 1000 * 60 * 60 * 24;   
        let days = Math.floor(diff/day);
        let years = Math.floor(days/365);
        return years;
    };


    return (
    <>
        <div className="page-title">
            <h1>Curriculum Vitae</h1>
            <div className="page-subtitle">
                <h4>Plus de {BeginWork()} Ans d'Experience</h4>
                <div className="home-buttons">
                    <a href={CV_JD} className="btn btn-primary" download="CV-Jean DELAGOUTTE" target='_blank'>   
                        <span>Télécharger mon CV</span>
                    </a>
                </div>
            </div>
        </div>
        <div id="content" className="page-content site-content single-post" role="main">
            <TimeLineBloc />
            <CertificateBloc/>
            <SkillBloc />
            <Interest />
        </div> 
    </>
    );
};

export default Resume;