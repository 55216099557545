import React from 'react';
import FunFact from '../AboutMe/FunFact';

const Interest = () => {
    
    const LstFunFacts = [
        { key: 1, faIcon: 'fa-solid fa-computer', Label: 'Informatique\nDomotique\nTechnologie'},
        { key: 2, faIcon: 'fa-solid fa-film', Label: 'Cinéma\nSérie\nManga'},
        { key: 3, faIcon: 'fa-solid fa-dumbbell', Label: 'Skate\nMarche\nYoga'},
      ];
    return (
        <>
        <div className="row">
            <div className="col-xs-12 col-sm-12">
            <div className="block-title">
                <h2>Centres d'intérêt</h2>
            </div>
            </div>
        </div>
        <div className="row">
            {LstFunFacts.map((CurFunFact) => (
                <FunFact key={CurFunFact.key} faIcon={CurFunFact.faIcon} Label={CurFunFact.Label} HowMany={CurFunFact.HowMany} OtherText={CurFunFact.OtherText} />
            ))}
        </div>
        </>
    );
};

export default Interest;