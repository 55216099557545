import React from 'react';
import { MapContainer, TileLayer, Popup, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';
import { useRef } from 'react';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});
L.Marker.prototype.options.icon = DefaultIcon;

const position = [43.6334222, -1.361178];

const Map = () => {
    const marker = useRef("");
    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12">
            <MapContainer whenReady={() => {
      setTimeout(() => {
        marker.current.openPopup();
      }, 1000);
    }} center={position} zoom={9} scrollWheelZoom={false} style={{ height: '30vh', width: '100wh', marginBottom:'50px' }}>
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    <Marker ref={marker} position={position}>
      <Popup>
        <span style={{textAlign:'center', display:'block'}}>Domicile :<br></br>Benesse-Maremne, 40230, France</span>
      </Popup>
    </Marker>
  </MapContainer>
                </div>

        </div>

    );
};

export default Map;
